










































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ITRQ_DTO, {ITRQDocumentDTO} from "@/dto/request/itrq/ITRQ_DTO";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";

@Component({
	computed: {
		MaritalStatus() {
			return MaritalStatus
		},
		dt() {
			return ITRQDocumentType
		}
	}
})
export default class ITRQSummary extends Vue {

	@Prop()
	questionnaire!: ITRQ_DTO;

	@Prop()
	singleDocuments!: Record<ITRQDocumentType, FileMetaDTO | null>

	@Prop()
	listDocuments!: Record<ITRQDocumentType, Array<FileMetaDTO>>;

	get q(): ITRQ_DTO{
		return this.questionnaire;
	}

	sd(type: ITRQDocumentType): FileMetaDTO | null {
		return this.singleDocuments[type];
	}

	ld(type: ITRQDocumentType): Array<FileMetaDTO> {
		return this.listDocuments[type];
	}

}
